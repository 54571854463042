<template>
    <div id="app">
        <keep-alive v-if="isRouterAlive">
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
import {config} from './api/api'
export default {
    data(){
        return {
            isRouterAlive : true,
        }
    },
    mounted(){
        // document.onkeydown = function() {
        //     var e = window.event || arguments[0];
        //     if (e.keyCode == 123) {
        //         return false;
        //     }
        // }
        // 屏蔽鼠标右键
        document.oncontextmenu = function() {
            return false;
        }


        config().then(res => {
            if(res.data.code == 10000){
                var obj = {}
                res.data.data.forEach(item => {
                    obj[item.key] = item
                })
                obj.nowTime = res.data.time * 1000
                localStorage.setItem("config", JSON.stringify(obj));
            }
        })
    }
}
</script>

<style lang="less">

</style>
