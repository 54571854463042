import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
var ua = navigator.userAgent,
isWindowsPhone = /(?:Windows Phone)/.test(ua),
isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
isAndroid = /(?:Android)/.test(ua),
isFireFox = /(?:Firefox)/.test(ua),
isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
isPhone = /(?:iPhone)/.test(ua) && !isTablet,
isPc = !isPhone && !isAndroid && !isSymbian;
const routes = [
	{
		path: '/Home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "about" */'@/views/Home/Home.vue'),
		meta:{keepAlive:true},
		children : [
			// {
			// 	path: '/cd',
			// 	name: 'beijing',
			// 	component: () => import(/* webpackChunkName: "about" */'@/views/beijing/beijing.vue'),
			// 	// meta:{keepAlive:true}
			// },
			{
				path: '/assembly',
				name: 'assembly',
				component: () => import(/* webpackChunkName: "about" */'@/views/assembly/assembly.vue'),
			},
			{
				path: '/video',
				name: 'video',
				component: () => import(/* webpackChunkName: "about" */'@/views/video/video.vue'),
			},
			{
				path: '/join',
				name: 'join',
				component: () => import(/* webpackChunkName: "about" */'@/views/join/join.vue'),
			},
			{
				path: '/about',
				name: 'about',
				component: () => import(/* webpackChunkName: "about" */'@/views/about/about.vue'),
			},
			{
				path: '/contact',
				name: 'contact',
				component: () => import(/* webpackChunkName: "about" */'@/views/contact/contact.vue'),
			},
		]
	},
	{
		path: '/MHome',
		name: 'MHome',
		component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/Home.vue'),
		meta:{keepAlive:true},
		children : [
			// {
			// 	path: '/Mcd',
			// 	name: 'Mbeijing',
			// 	component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/beijing.vue'),
			// 	// meta:{keepAlive:true}
			// },
			{
				path: '/Massembly',
				name: 'Massembly',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/assembly.vue'),
			},
			{
				path: '/Mvideo',
				name: 'Mvideo',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/video.vue'),
			},
			{
				path: '/Mjoin',
				name: 'Mjoin',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/join.vue'),
			},
			{
				path: '/Mabout',
				name: 'Mabout',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/about.vue'),
			},
			{
				path: '/Mcontact',
				name: 'Mcontact',
				component: () => import(/* webpackChunkName: "about" */'@/views/Mobile/contact.vue'),
			},
		]
	},
	{
        path: '/',
        redirect: '/assembly',
    },
	{
        path: '*',
        redirect: '/assembly',
    },
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass:'active',
    scrollBehavior: () => ({ y: 0 }),  //路由跳转后页面回到顶部
	routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
	document.querySelector('#app').scrollTop = 0;
	if(isPc){
		next()
	}else{//移动端页面跳转
		if(to.name == 'assembly'){
			next({ path:'/Massembly' })
		}else if(to.name == 'video'){
			next({ path:'/Mvideo' })
		}else if(to.name == 'about'){
			next({ path:'/Mabout' })
		}else if(to.name == 'contact'){
			next({ path:'/Mcontact' })
		}else if(to.name == 'join'){
			next({ path:'/Mjoin' })
		}else{
			next()
		}
		next()
	}
    
})
export default router
