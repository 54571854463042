import Vue from 'vue';
import {
    Button,
    Carousel,
    CarouselItem,
    input,
    drawer,
    upload,
    Message,
} from 'element-ui'

Vue.use(upload)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(input)
Vue.use(drawer)
Vue.prototype.$message = Message