import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store =  new Vuex.Store({
	state: {
		language : {
			type : 1, // 语言：1：中文，2：英文
		}
	},
	mutations: {
		LanguageFn(state , payload){
			this.state.language = payload
		}
	},
	actions: {
	},
	modules: {
	}
})
export default store
